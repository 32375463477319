.sidebar {
  height: 100vh;
  background-color: white !important;
  text-align: left;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

  .logo {
    //height: 140px;
    padding: 20px 20px 60px 20px;
  }

  .ant-menu-inline > .ant-menu-item {
    height: 60px;
    line-height: 40px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff8f6;
  }
  .ant-menu-inline .ant-menu-item:after {
    border-right: 5px solid #d94521;
  }
}
#sidebarLinkTag {
  text-decoration: "none";
  font-size: "16px";
  padding-left: "10px";
}
