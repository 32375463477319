.m-5 {
  margin: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-tb-5 {
  margin: 5px 0px;
}

.m-lr-5 {
  margin: 0px 5px;
}

.m-10 {
  margin: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-tb-10 {
  margin: 10px 0px;
}

.m-lr-10 {
  margin: 0px 10px;
}

.m-15 {
  margin: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-tb-15 {
  margin: 15px 0px;
}

.m-lr-15 {
  margin: 0px 15px;
}

.m-20 {
  margin: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-tb-20 {
  margin: 20px 0px;
}

.m-lr-20 {
  margin: 0px 20px;
}

.m-25 {
  margin: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-tb-25 {
  margin: 25px 0px;
}

.m-lr-25 {
  margin: 0px 25px;
}

.m-30 {
  margin: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-tb-30 {
  margin: 30px 0px;
}

.m-lr-30 {
  margin: 0px 30px;
}
