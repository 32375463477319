// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

@import "../abstracts/variables";
@import "../abstracts/mixins";

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: "Montserrat", sans-serif;
}

/**
 * Basic styles for links
 */
a {
  color: red;
  color: #0087fc;
  text-decoration: none;

  // @include mixins.on-event {
  //   color: variables.$text-color;
  //   text-decoration: underline;
  // }
}
.no-border {
  border: none;
  text-decoration: none !important;
  color: black;
}
