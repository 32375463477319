.summary {
  height: 100vh;
  .project-header {
    padding: 2% 5%;
    .myProfile {
      text-align: left;
      &-subHeadings {
        padding-left: 6px;
      }
      &-link {
        color: black;
        text-decoration: none;
      }
    }
    .project-crumbItem {
      font-size: 18px;
      font-weight: bold;
      text-transform: capitalize;
    }
    .project-crumbItem :link {
      text-decoration: none;
    }
    .ant-breadcrumb-separator {
      // padding-top: 18px;
      font-size: 22px;
      font-weight: bold;
      color: #000;
    }
    .ant-breadcrumb ol {
      align-items: center;
    }
    .invoiceNo {
      font-size: 23px;
    }
    .invoice-btn {
      border: none;
      border-radius: 5px;
    }
  }

  .img-heading {
    padding: 40px 60px;
    display: flex;
    .img_content {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
    .img_content div {
      font-weight: bold;
      padding-top: 5px;
      font-size: 16px;
      text-align: left;
      text-transform: capitalize;
    }
    .img_content h3 {
      padding-top: 10px;
      text-align: left;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .final-invoice-table {
    padding: 0px 60px;
    .approve-modal-table {
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background: #a39f9f61;
        border-radius: 5px;
      }

      border: 2px solid #f0f0f0;
      border-radius: 10px;
      overflow: hidden;
      border-collapse: separate !important;
      .ant-table-thead > tr > th {
        background-color: #eef4fc;
        border: none;
        font-size: 16px;
        font-weight: bold;
      }

      .ant-table-tbody > tr > td {
        border: none !important;
        color: #000;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .approve-total {
      padding: 30px 20px;
      font-size: 16px;
      text-align: left;
    }
    .total-amount-line {
      text-align: center;
      padding: 15px 0px;
      background-color: #ddffd7;
      border-radius: 5px;
      font-size: 16px;
    }
    .approve-modal-btn {
      display: flex;
      height: 50px;
      text-align: center;
      // padding: 18px 0px;
      background-color: #1890ff;
      border-radius: 5px;
      color: white;
      font-size: 15px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
    // .proceed-to-pay-fiat {
    //   padding: 0px 0px;
    // }
    .cancel-modal-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      text-align: center;
      padding: 18px 0px;
      background-color: white;
      border-radius: 5px;
      color: #1890ff;
      font-size: 15px;
      cursor: pointer;
    }
    .approve-modal-meta {
      text-align: center;
      font-size: 16px;
    }
  }
  .approved-page {
    // padding: 0px 60px;
    .success-heading {
      font-size: 16px;
      margin-bottom: -15px;
    }
    .checked-icon {
      font-size: 150px;
      color: #f0ca07;
    }
    .successIcon {
      font-size: 150px;
      color: #00e06a;
    }
    .icon-bottom-line {
      font-size: 16px;
      color: #b1b1b1;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .approve-pay-btn {
      padding: 0px 60px;
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }
    .approve-close-btn {
      padding: 0px 71px;
      border-radius: 5px;
      font-weight: bold;
      color: #b1b1b1db;
    }
    .approve-total {
      padding: 20px 60px;
      font-size: 16px;
    }
  }
}
