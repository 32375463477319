.p-5 {
  padding: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-tb-5 {
  padding: 5px 0px;
}

.p-lr-5 {
  padding: 0px 5px;
}

.p-10 {
  padding: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-tb-10 {
  padding: 10px 0px;
}

.p-lr-10 {
  padding: 0px 10px;
}

.p-15 {
  padding: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-tb-15 {
  padding: 15px 0px;
}

.p-lr-15 {
  padding: 0px 15px;
}

.p-20 {
  padding: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-tb-20 {
  padding: 20px 0px;
}

.p-lr-20 {
  padding: 0px 20px;
}

.p-25 {
  padding: 25px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-tb-25 {
  padding: 25px 0px;
}

.p-lr-25 {
  padding: 0px 25px;
}

.p-30 {
  padding: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-tb-30 {
  padding: 30px 0px;
}

.p-lr-30 {
  padding: 0px 30px;
}
