.ant-modal {
  .ant-modal-content {
    border-radius: 12px;
    padding-top: 15px;
    padding-bottom: 35px;
    .ant-modal-header {
      border-radius: 12px;
      .ant-modal-title {
        color: #18b578;
      }
    }
  }
}
