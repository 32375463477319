.settingPage {
  height: 100vh;
  .project-header {
    padding: 2% 2% 2% 5%;
    .myProfile {
      text-align: left;
      &-subHeadings {
        // padding-left: 6px;
      }
      &-link {
        color: black;
        text-decoration: none;
      }
    }
    .project-crumbItem {
      font-size: 18px;
      font-weight: bold;
    }
    .ant-breadcrumb-separator {
      // padding-top: 18px;
      font-size: 22px;
      font-weight: bold;
      color: #000000;
    }
    .ant-breadcrumb ol {
      align-items: center;
    }
  }
  .profile-tabs {
    padding: 15px 60px;

    .profile-active-tabs {
      color: #a39f9f;

      .ant-tabs-nav .ant-tabs-ink-bar {
        height: 4px;
        border-radius: 2px;
      }
    }
  }
  .profile-section {
    padding: 20px 104px;
    .form-row {
      padding: 20px 0px;
    }
    .myProfile-input {
      width: 545px;
      font-family: "Montserrat", sans-serif;
      border-radius: 5px;
    }
    .myProfile-btn-save {
      border: none;
      border-radius: 5px;
      margin-top: 20px;
    }
    .btn-logout {
      color: #005cf6;
      margin-top: 30px;
      font-size: 16px;
      cursor: pointer;
    }
    .project-btn {
      background: #005cf6;
      border-radius: 5px;
      width: 90px;
    }
  }
  .companyProfile-input {
    width: 545px;
    text-align: left;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
  }
  .apikey-input {
    // padding: 40px 0px;
    width: 450px;
    display: flex;
    justify-content: flex-start;
  }
  .apikey-label {
    // padding: 40px 0px;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
    width: 200px;
    text-align: left;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
  .border-less-input {
    text-align: left;
    border-right: "none";
    border-radius: 5px 0 0 5px !important;
  }

  .border-less-copy-btn {
    border-left: none;
    width: 100px;
    border-radius: 0px 5px 5px 0px !important;
  }
  .border-less-input:focus {
    border-color: #ccc !important;
    border-right: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .border-less-input:hover {
    border-color: #ccc;
    border-right: none;
  }
  .border-less-copy-btn:hover {
    border-color: #ccc;
  }
  .border-less-copy-btn:focus {
    border-color: #ccc;
  }
  .generate-key-btn {
    padding: 0px 46px;
    border-radius: 5px;
  }
  .generate-key-btn-left {
    padding: 0px 27px;
    border-radius: 5px;
  }
  .document-key-btn {
    padding: 0px 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-col {
    margin-right: 10px;
  }
}
